/**
 *
 * @param {string} alternateSlug slug
 * @param {string} contentType type of editorial content
 * @param {string} alternateLang Language code
 * @returns
 */
export function formatAlternatePath(alternateSlug, contentType, alternateLang) {
  return (
    "/" +
    [alternateLang, contentType, alternateSlug]
      .filter(Boolean)
      .map(encodeURIComponent)
      .join("/")
  );
}

export function getAlternateLang(language) {
  return language === "en" ? "es" : "en";
}

export const professionOptionsEn = [
  {
    id: "endocrinologist",
    content: "Endocrinologist",
  },
  {
    id: "general-practitioner",
    content: "General Practitioner",
  },
  { id: "internal-medicine", content: "Internal Medicine" },
  { id: "vascular-surgeon", content: "Vascular Surgeon" },
  { id: "coloproctologist", content: "Coloproctologist" },
  { id: "diabetologist", content: "Diabetologist" },
  { id: "nephrologist", content: "Nephrologist" },
  { id: "cardiologist", content: "Cardiologist" },
  { id: "geriatrician", content: "Geriatrician" },
  { id: "psychiatrist", content: "Psychiatrist" },
  { id: "neurologist", content: "Neurologist" },
  { id: "pharmacist", content: "Pharmacist" },
  { id: "anesthetist", content: "Anesthetist" },
  { id: "cardiothoracic-surgeon", content: "Cardiothoracic Surgeon" },
  { id: "dermatologist", content: "Dermatologist" },
  { id: "emergency-medicine", content: "Emergency Medicine" },
  { id: "family-medicine", content: "Family medicine" },
  { id: "gastroenterologist", content: "Gastroenterologist" },
  { id: "gerontologist", content: "Gerontologist" },
  { id: "gynecologist", content: "Gynecologist" },
  { id: "hematologist", content: "Hematologist" },
  { id: "nurse-practitioner", content: "Nurse Practitioner" },
  { id: "obstetrician", content: "Obstetrician" },
  { id: "oncologist", content: "Oncologist" },
  { id: "orthopedist", content: "Orthopedist" },
  { id: "phlebologist", content: "Phlebologist" },
  { id: "proctologist", content: "Proctologist" },
  { id: "resident", content: "Resident" },
  { id: "surgeon", content: "Surgeon" },
  { id: "rheumatologist", content: "Rheumatologist" },
  { id: "urologist", content: "Urologist" },
  { id: "other", content: "Other" },
];

export const professionOptionsEs = [
  {
    id: "endocrinologist",
    content: "Endocrinólogo",
  },
  {
    id: "general-practitioner",
    content: "Médico generalista",
  },
  { id: "internal-medicine", content: "Médico internista" },
  { id: "vascular-surgeon", content: "Cirujano vascular" },
  { id: "coloproctologist", content: "Coloproctólogo" },
  { id: "diabetologist", content: "Diabetólogo" },
  { id: "nephrologist", content: "Nefrólogo" },
  { id: "cardiologist", content: "Cardiólogo" },
  { id: "geriatrician", content: "Geriatra" },
  { id: "psychiatrist", content: "Psiquiatra" },
  { id: "neurologist", content: "Neurólogo" },
  { id: "pharmacist", content: "Farmacéutico" },
  { id: "anesthetist", content: "Anestesista" },
  { id: "cardiothoracic-surgeon", content: "Cirujano cardiotorácico" },
  { id: "dermatologist", content: "Dermatólogo" },
  { id: "emergency-medicine", content: "Medicina de Urgencias" },
  { id: "family-medicine", content: "Médico de Familia" },
  { id: "gastroenterologist", content: "Gastroenterólogo" },
  { id: "gerontologist", content: "Gerontólogo" },
  { id: "gynecologist", content: "Ginecólogo" },
  { id: "hematologist", content: "Hematólogo" },
  { id: "nurse-practitioner", content: "Enfermero de cabecera" },
  { id: "obstetrician", content: "Obstétrico" },
  { id: "oncologist", content: "Oncólogo" },
  { id: "orthopedist", content: "Ortopedista" },
  { id: "phlebologist", content: "Flebólogo" },
  { id: "proctologist", content: "Proctólogo" },
  { id: "resident", content: "Residente" },
  { id: "surgeon", content: "Cirujano" },
  { id: "rheumatologist", content: "Reumatólogo" },
  { id: "urologist", content: "Urólogo" },
  { id: "other", content: "Otros" },
];
